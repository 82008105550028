import React, {
    useState,
    useCallback,
  } from 'react'
  import { 
    Banner,
    Modal,
    TextField,
    Button,
    Toast,
  } from "@shopify/polaris"
  import styled from 'styled-components'
  import sendEarlyRequestEmail from '../../helpers/sendEarlyRequestEmail.js'
  const ButtonWrapper = styled.div`
    margin-top: 15px;
    .Polaris-Button {
        background: #AEB4B9;
        color: #fff;
    }
`
  const EarlyAccessBanner = (props) => {
    const [bannerActive, setBannerActive] = useState(true);
    const [email, setEmail] = useState(null);
    const [requestSent, setRequestSent] = useState(false);
    const [toastActive, setToastActive] = useState(null);
    const toggleToastActive = () => setToastActive(null)
    const [loading, setLoading] = useState(false);
    const [requestModalActive, setRequestModalActive] = useState(false);
    const handleRequestModalChange = useCallback(() => setRequestModalActive(!requestModalActive), [requestModalActive]);
    const handleEmailChange = useCallback((newValue) => setEmail(newValue), []);
   
    const sendRequest = async () => {
      if(!validateEmail(email)){
          setToastActive("WrongEmail")
      }else{
          setLoading(true)
          const result = await sendEarlyRequestEmail({
              email: email
          }, props.token, props.shop, props.host);
          setLoading(false)
          if(200 === result.status){
              setRequestModalActive(false)
              setToastActive("Success")
              setRequestSent(true)
          }else{
              setToastActive("Error")
          }
      
      }
  }
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  const toastMarkup = toastActive && "Success" === toastActive ? (
    <Toast content="We emailed you with a link for early access to our new app!" onDismiss={toggleToastActive} />
  ) : (toastActive && "WrongEmail" === toastActive ? (
    <Toast 
    content="Please enter a valid email address." 
    onDismiss={toggleToastActive} error/>
  ) : (toastActive && "Error" === toastActive ? <Toast 
  content="Something is wrong. Please contact us in the chat on the bottom right." 
  onDismiss={toggleToastActive} error/>:null));


    return (
      <div style={{marginBottom: '15px'}}>
        {bannerActive &&
        <Banner
              title="NEW: Customize your checkout further with Checkout Extensions Plus"
              status="info"
              onDismiss={() => {setBannerActive(false)}}
                >
               <p>Looking to add custom branding, shipping options, payment options and content to your new checkout? We’re building something new!
                 {
                 requestSent ?
                   <ButtonWrapper>
                       <Button primary disabled>Access requested!</Button>
                   </ButtonWrapper>
                    :
                    <Modal
                        activator={( <div className='request-early-access' style={{ marginTop: '15px'}}>
                        <Button primary onClick={handleRequestModalChange} loading={loading}>Get early access</Button>
                    </div>)}
                        open={requestModalActive}
                        onClose={handleRequestModalChange}
                        title="Enter your email"
                        primaryAction={{
                        content: 'Get early access',
                        onAction: sendRequest,
                        }}
                    >
                        <Modal.Section>
                            <TextField
                                label="Email"
                                labelHidden
                                type="email"
                                value={email}
                                onChange={handleEmailChange}
                                autoComplete="email"
                            />
                        </Modal.Section>
                    </Modal>
                  }
               </p>
             </Banner>
          }
          {toastMarkup}
      </div>
    );
  }
  
  export default EarlyAccessBanner;